.TimePicker___UXFCC .time-picker-wrapper {
  display: flex;
}
.TimePicker___UXFCC .time-picker-wrapper .picker-split {
  display: inline-flex;
  margin: 0 4px;
  justify-content: center;
  align-items: center;
}
.TimePicker___UXFCC .ant-input {
  line-height: 22px;
  padding: 4px 10px;
}
.TimePicker___UXFCC .ant-segmented-item {
  color: #000;
  padding: 4px 0;
  width: 50%;
  border: none;
  box-shadow: none;
}
.TimePicker___UXFCC .ant-segmented-item .ant-segmented-item-label {
  line-height: 20px;
  min-height: 20px;
}
.TimePicker___UXFCC .ant-segmented-item-selected {
  font-weight: 700;
}
